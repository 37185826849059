<template>
  <form class="signin-form form view-login-form">
    <form-group :field="$v.phone" :label="$t('signin.phone-placeholder')" name="phone">
      <el-input
          :placeholder="$t('signin.phone-placeholder')"
          v-model="phone"
          v-mask="'+7 (###) ### ##-##'"
          @input="enforcePhoneFormat"
          @blur="onGetEmail"
          :disabled="emailLoading"
          autocomplete="off">
      </el-input>
    </form-group>
    <div>
      <form-group @submit="submit(account)" :field="$v.account.email" :label="$t('signin.select-user-label')" name="email">
        <el-select style="width: 100%" v-model="account.email" :no-data-text="$t('signin.empty')" :placeholder="$t('signin.select-user-label')" :loading="emailLoading">
          <el-option
              v-for="email in emailArray"
              @change="$v.account.email.$touch()"
              :key="email.value"
              :label="email.label"
              :value="email.value">
          </el-option>
        </el-select>
      </form-group>
      <form-group :field="$v.account.password" :label="$t('signin.password-label')" name="password">
        <el-input
            :placeholder="$t('signin.password-label')"
            v-model="account.password"
            @input="onPasswordInput"
            @change="$v.account.password.$touch()"
            autocomplete="off"
            :disabled="emailLoading"
            show-password
            @keyup.enter.native="submit(account)">
        </el-input>
      </form-group>
      <div class="row form-group mt-4 forget-block-main">
        <div class="col-6">
          <el-checkbox :disabled="emailLoading">{{ $t('signin.remember-me') }}</el-checkbox>
        </div>
        <div class="col-6 text-right">
          <router-link to="/forget-password" tag="el-link" style="text-decoration: none" :disabled="emailLoading">{{ $t('signin.forgot-password') }}</router-link>
        </div>
      </div>
      <div class="row d-none">
        <div class="col-12">
          <router-link to="/signup" tag="el-link" style="text-decoration: none" :disabled="emailLoading">{{ $t('signin-form.dont-have-account') }} <span class="text-primary">{{ $t('signin-form.signup') }}</span></router-link>
        </div>
      </div>

      <div class="form-group text-center signin-form__button">
        <el-button class="btn btn-primary btn-block order-2 order-lg-3" type="primary" @click.prevent="submit(account)" :loading="loading" :disabled="emailLoading || phone.length < 18">{{ $t('signin.button') }}</el-button>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-center">
          <router-link class="signin-form__forget-password" to="/forget-password" tag="el-link" style="text-decoration: none" :disabled="emailLoading">{{ $t('signin.forgot-password') }}</router-link>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Vue from 'vue'
import VueMask from 'v-mask'
import { mapActions } from 'vuex'
import { minLength } from 'vuelidate/lib/validators'

export default {
  name: 'LoginForm',
  beforeCreate() {
    Vue.use(VueMask);
  },
  data () {
    return {
      emailLoading: false,
      loading: false,
      phone: '',
      emailArray: [],
      account: {
        phone: '',
        email: '',
        password: ''
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    onPasswordInput(e){
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: 'Error',
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      this.account.password = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
    },
    addOptionsToSelect() {
      this.emailArray = [];
      let data = JSON.parse(sessionStorage.getItem('children'));

      data.forEach(element => {
        let item = {};
        item.label = element.last_name + " " + element.first_name;
        item.value = element.email;
        this.emailArray.push(item);
      });
      sessionStorage.removeItem('children');
    },
    onGetEmail() {
      if (!this.$v.account.phone.$invalid){
        if (this.account.phone !== '') {
          this.emailLoading = true;
          this.getEmail({
            phone: this.account.phone
          }).then(() => {
            this.emailLoading = false;
            this.addOptionsToSelect()
          }).catch((e) => {
            this.emailLoading = false;
            this.phone = '';
            console.log(e);
          })
        }
      }
    },
    enforcePhoneFormat() {
      this.$v.phone.$touch();
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.account.phone = x[0];
    },
    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.loading = true;
      this.login(this.account)
        .then(() => {
          this.loading = false;
          sessionStorage.removeItem('children');
          if (localStorage.getItem('role_id') === '1') {
            window.location.href = this.$route.query.redirect || '/profile'
          } else if (localStorage.getItem('role_id') === '2') {
            window.location.href = this.$route.query.redirect || '/profile-teacher'
          }
        })
        .catch(() => {
          Vue.toastr({
            message: 'Error',
            description:  $t('signin-form.error'),
            type: 'error'
          })
          this.loading = false;
        })
    },
    ...mapActions({
      'getEmail': 'getEmail',
      'login': 'login'
    })
  },
  validations: {
    phone: {
      required: false,
      minLength: minLength(18)
    },
    account: {
      phone: {
        required: false,
        minLength: minLength(11)
      },
      email: {
        required: false,
      },
      password: {
        required: false,
        minLength: minLength(8)
      }
    }
  }
}
</script>

<style>
.el-select-dropdown__empty{
  padding: 10px;
}
.el-input__inner {
  box-shadow: 0 4px 4px rgba(51, 51, 51, 0.04), 0 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 4px;
  border: none;
}
.signin-form__forget-password span {
  color: #6E41E2;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}
.signin-form__forget-password span:hover {
  color: #6E41E2;
  text-decoration: none;
}
.jumbotron .signin-form__button {
  padding-top: 18px;
}
.jumbotron .signin-form__button .btn{
    border: none;
}
.jumbotron .signin-form__forget-password {
  display: none;
}
.teacher-page .signin-form__button {
  padding-top: 172px;
}
.teacher-page .forget-block-main {
  display: none;
}
</style>