<template>
    <div class="container py-5">
        <div class="row mt-5 pb-5">
            <div class="col-12">
                <div class="welcome-text">
                    <h1>{{ $t("reset-password.title") }}</h1>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="w-100">
                    <div class="form-group my-4">
                        <form-group :field="$v.password" :label=" $t('signin-form.password-label') + $t('references.required-field')" name="password">
                            <el-input
                                :placeholder="$t('signin-form.password-placeholder')"
                                v-model="password"
                                @input="onPasswordInput"
                                @change="$v.password.$touch()"
                                show-password
                            ></el-input>
                        </form-group>
                        <form-group :field="$v.passwordConfirm" :label=" $t('signin-form.password-confirm-label') + $t('references.required-field')" name="password">
                            <el-input
                                :placeholder="$t('signin-form.password-confirm-placeholder')"
                                v-model="passwordConfirm"
                                @input="onPasswordConfirmInput"
                                @change="$v.passwordConfirm.$touch()"
                                show-password
                            ></el-input>
                        </form-group>
                        <div class="form-group text-center pt-3">
                            <el-button type="primary" @click="savePassword">{{ $t('reset-password.recovery-btn') }}</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="py-5">
                    <transition name="hint" appear>
                        <div v-if='passwordValidation.errors.length > 0 && !submitted' class='hints'>
                            <p :class="{'text-success': error.active, 'text-danger': !error.active}" v-for='error in passwordValidation.errors'><span></span>{{error.message}}</p>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import LoginForm from '@/components/LoginForm';
import { minLength, required } from "vuelidate/lib/validators";
export default {
    name: 'Login',
    components: {
        LoginForm
    },
    data(){
        return {
            passwordValid: false,
            password: '',
            passwordConfirm: '',
            token: this.$route.query.token,
            iin: this.$route.query.iin,
            rules: [
                { active: false, message: this.$t('reset-password.minimum-symbols'), regex:/.{8,}/ },
                { active: false, message: this.$t('reset-password.number-required'), regex:/[0-9]+/ },
                // { active: false, message: this.$t('reset-password.lowercase-required'), regex:/[a-z]+/ },
                // { active: false, message: this.$t('reset-password.uppercase-required'),  regex:/[A-Z]+/ },
                // { active: false, message:"Допустимые символы: ! . - _ ?",  regex:/[!.\-_?]/ },
            ],
            submitted:false,
        }
    },
    validations: {
        password: {
            required,
            minLength: minLength(6)
        },
        passwordConfirm: {
            required,
            minLength: minLength(6)
        }
    },
    methods: {
        onPasswordInput(e){
            if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
                Vue.toastr({
                    message: 'Error',
                    description: this.$t('reset-password.enter-only-latin-letters'),
                    type: 'error'
                })
            }
            this.password = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
        },
        onPasswordConfirmInput(e){
            if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
                Vue.toastr({
                    message: 'Error',
                    description: this.$t('reset-password.enter-only-latin-letters'),
                    type: 'error'
                })
            }
            this.passwordConfirm = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
        },
        savePassword() {
            if (this.password !== this.passwordConfirm) return;
            if (!this.passwordValid) return;
            this.$http.post(window.API_ROOT + '/api/password/reset/confirmation', {
                token: this.token,
                password: this.password,
                iin: this.iin
            })
                .then((res) => {
                    Vue.toastr({
                        message: res.body.status,
                        description: res.body.message,
                        type: res.body.status
                    })
                    if (res.body.status == 'success') {
                        this.$router.push({path: '/'})
                    }
                })
                .catch((e) => {
                    Vue.toastr({
                        message: 'Error',
                        description: e.body.message,
                        type: 'error'
                    })
                })

        }
    },
    computed: {
        notSamePasswords () {
            if (this.passwordsFilled) {
                return (this.password !== this.password_confirmation)
            } else {
                return false
            }
        },
        passwordsFilled () {
            return (this.password !== '' && this.password_confirmation !== '')
        },
        passwordValidation () {
            let errors = []
            for (let condition of this.rules) {
                condition.active = condition.regex.test(this.password);
                // if (!condition.regex.test(this.password)) {
                errors.push({
                    message: condition.message,
                    active: condition.active
                });
                // }
            }
            let error = false;
            Object.entries(errors).forEach(entry => {
                const [key, value] = entry;
                if (value.active == false){
                    error = true;
                }
            });
            this.passwordValid = !error;
            return { valid:!error, errors }
        }
    }
}
</script>
<style>
.text-danger span{
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('/images/close.svg');
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: center;
    background-color: #dc3545;
    float: left;
    border-radius: 24px;
    margin-right: 10px;
}
.text-success span{
    display: block;
    width: 24px;
    height: 24px;
    background-image: url("/images/ok.svg");
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    background-color: #018837;
    float: left;
    border-radius: 24px;
    margin-right: 10px;
}
.el-alert .el-alert__title{
    text-transform: capitalize;
}
</style>
